<template>
  <div>
    <HeroSmall :page_title="page_title" :leadline="leadline" />
    <div v-if="loading">
      <section class="py-5">
         <div class="container">
          <div class="row justify-content-center my-5">
            <div class="col-lg-9" >
              <card shadow body-classes="py-5">
                Loading...
              </card>
            </div>
          </div>
         </div>
      </section>
      
    </div>
    <!-- <div v-if="!loading && !current_openings"> -->
    <div v-if="!loading && !current_openings">
      <section class="py-5">
         <div class="container">
          <div class="row justify-content-center my-5">
            <div class="col-lg-9" >
              <card class="border-0" hover shadow body-classes="py-5 min-card-height">
                <icon name="fas fa-leaf" type="success" rounded class="mb-4">
                </icon>
                <h3 class="text-success text-uppercase">There are no active job listings.</h3>
                <p class="description mt-3">Fill out our on-line application or download a copy to apply for other positions that may not be currently available.</p>
                <router-link to="/employment/application" class="btn btn-success mt-4">Apply Now!</router-link>
              </card>
            </div>
          </div>
         </div>
      </section>
      <hr/>
    </div>
    <div v-if="!loading && current_openings">
      <section class="py-5">
        <div class="container">
          <div class="row justify-content-center my-5" v-for="i in jobs" :key="i.key">
            <div class="col-lg-9" >
              <card shadow body-classes="py-5">
                <h3 slot="header" class="heading-title text-center">{{ i.title }}</h3>
                <div v-html="i.description" class="mb-4"></div>
                <base-button v-if="show_more != i.key " type="info" @click="show_more = i.key">Show More Details</base-button>
                <section v-if="show_more == i.key">
                  <hr>
                  <h4>Qualifications</h4>
                  <ul>
                    <li v-for="(q, index) in i.qualifications" :key="index">{{ q.desc }}</li>
                  </ul>
                  <hr>
                  <h4>Responsibilities</h4>
                  <ul>
                    <li v-for="(r, index) in i.responsibilities" :key="index">{{ r.desc }}</li>
                  </ul>
                  <base-button type="info" @click="show_more = ''">Hide Details</base-button>
                  <router-link class="btn btn-success" :to="`/employment/application?job_title=${i.title}&job_key=${i.key}`">Apply Now</router-link>
                </section>
              </card>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import HeroSmall from '@/views/components/HeroSmall.vue'
  import Card from '@/components/Card.vue'

  import {
    jobsRef
  } from '@/firebase'
  export default {
    name: 'job',
    components: {
      HeroSmall,
      Card
    },
    data: () => ({
      loading: true,
      current_openings: false,
      show_more:'',
      page_title: 'Job Listings',
      leadline: 'Join the HealthView Team',
      jobs: []
    }),
    methods: {
      getJobs() {
        jobsRef.orderByChild('active').equalTo(true).once('value', res => {
          res.forEach(i => {
            let obj = i.val()
            obj.key = i.key
            this.jobs.push(obj)
          })
        }).then(() => {
          this.loading = false
          if (this.jobs.length > 0) {
            this.current_openings = true
          }
        })
      }
    },
    created() {
      this.getJobs()
    }
  }
</script>

<style>

</style>